@media screen and (min-width: 1025px) {
    .ideaboxform {
        width: 70%;
        margin: auto;
    }

}

.contactdetail input[type="tel"] {
    padding-left: 45px !important;
    width: 100%;
}
.ant-picker-header button
{
    min-width: 10px !important;
    width: auto !important;
}
.benji-img
{
    position: absolute;
    right: 0;
    bottom: 0;
}
.benji-img img
{
    height: 15rem !important;
}
@media screen and (min-width:0px) and (max-width:767px)
{
    .benji-img-sm img
    {
        height: 10rem !important;
    }
    .language-dropdown
    {
        width: 25%;
    }
    .ideabox-back
    {
        width: 55px;
        height: 40px;
    }
    .ideabox-back img
    {
        width: 33px !important;
        height: 33px !important;
    }
}
.ideabox-back img
{
      background-color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      text-align: center;
      cursor: pointer;
      padding: 0.5rem;
}
.ideabox-back img
{
    width: 33px !important;
    height: 33px !important;
}
.addlunchlable {
    font-size: 18px !important;
}
.sectionTitle {
    font-size: 19px;
}
.ideabox-container {
    width: 92% !important;
    margin-left: auto;
}
@media screen and (min-width:768px) and (max-width:1023px)
{
    .benji-img-sm img
    {
        height: 15rem !important;
    }
    .ideabox-container
    {
        width: 98% !important;
        padding-right: 1rem !important;
    }
    .Tabletcontainer, .Tabletcontainer .navbar
    {
        width: 95% !important;
    }
}
@media screen and (min-width:0px) and (max-width:767px)
{
    .ideabox-container
    {
        width: 100% !important;
        padding: 1rem !important;
    }
}
.react-tel-input .form-control
{
    width: 100% !important;
    border-radius: 0px !important;
}
.ideaboxform .form-group .form-control
{
    height: 45px !important;
}
.ideabox-back
{
    left: 1rem;
}
.ideaboxform .ant-select-selector
{
        height: 45px !important;
}
.ideaboxform .ant-select-selection-item,
.ideaboxform .ant-select-selection-placeholder
{
    display: flex;
    align-items: center;
}
.ideaboxform .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input
{
    height: 100% !important;
}
