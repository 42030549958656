*{
    box-sizing: border-box;
}
p{
    margin: 0px;
}
.popup-page{
    width: 100vw;
    height: 100vh;
    background: rgb(207, 207, 207);
    padding: 50px;
}

/* .mentions--multiLine .mentions__input {
    min-height: 0px !important;
} */
.meeting-popup textarea:focus {
  outline:0;
  box-shadow:0 0 0 .1rem rgb(126, 169, 235)
}

.meeting-popup textarea ,.meeting-popup .mentions__highlighter{
    margin: 0px 0px 0px -3px;
    height: 25px !important;
    border: none !important;
    padding: 0px 22px 0px 12px !important;
    white-space: nowrap;
    min-height: 0px !important; 
}

.meeting-popup .mentions__suggestions{
    max-width: 95% !important;
    margin-top: 25px !important;
}
.meeting-popup .mentions__suggestions__list{
    overflow-x: hidden !important;
}
.meeting-popup .mentions__suggestions__list::-webkit-scrollbar {
    width: 5px;
    
  }   
  .meeting-popup .mentions__suggestions__list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
      border-radius: 10px;
  }
  
  .meeting-popup .mentions__suggestions__list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  }
  
  
  .meeting-popup .mentions__suggestions__list::-webkit-scrollbar-thumb:hover {
    background: #ddd;
    padding-top: 20px;
  } 

.meeting-popup .mentions__suggestions__item--focused {
    background-color : #d20c14 !important;
    color: #fff !important;
}

.btn{
    padding: 5px 20px;
}

.popup-btn {
    text-align: center;
    /* padding-top: 40px; */
}

/* css Style code for popup container */

.popup-container{
    position: fixed;
    width: 100v;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);

}

.popup-inner-div{
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: white;
}

.popup-title{
    background-color: rgb(78, 78, 78);
}

#popup-title{
    padding: 10px;
    color: white;
    font-family: "BrandonGrotesque-Bold";
    letter-spacing: 1px;
    text-transform: uppercase;
}

.popup-body{
    height: 100px;
}

  
  /* .btn::after {
    content: "";
    width: 10px;
    height: 18px;
    position: absolute;
    border-left: 1px solid #ddd;
    right: 80px;
      bottom: -10px;
    transform: rotate(125deg);
    background: #ddd;
  } */

.user-body-div {
    width: 85%;
    margin: auto;
}
.createUsers .popup-btn {
    text-align: right;
    padding-right: 2%;
}

.popup-title{
    position: relative;
}

.popup-title:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    border-top: 25px solid #cecece52;
    border-left: 25px solid #4e4e4e;
    width: 0;
    background: none;
    outline: 0;
}

.deletepopup .popup_body {
    width: 100%;
    float: left;
    margin-top: 12%;
    margin-bottom: 4%;
}

.manage-addr-main-div .btn:after {
    border-left: 1px solid #ddd !important;
    background-color: #ddd!important;
    right: 80px !important;
}

.popup_body {
    margin-top: 4%;
    /* padding-top: 50px; */

}
select {
    background-color: #fff;
}

.user-section1 {
    width: 100%;
    float: left;
    padding: 10px 20px;
}

.user-section4 {
    clear: left;
}
 .user-section5,
 .user-section4,
 .user-section2,
 .user-section3  {
    width: 50%;
    float: left;
    padding: 10px 20px;

}

.user-section5 input,
.user-section4 input,
.user-section2 input,
.user-section3 input {
    float: left;
}


.manage-user .user-section2, 
.manage-user  .user-section3, 
.manage-user .user-section4, 
.manage-user  .user-section5,
.manage-user  .user-section1 {
 width: 100% !important;
 padding: 0px 15px !important;
}

.manage-user {
    margin-top: -32px;
}

.manage-user .user-section2 input, 
.manage-user  .user-section3 input, 
.manage-user .user-section4 input, 
.manage-user  .user-section5 input,
.manage-user  .user-section1 input,
.manage-user  select {
    padding: 8px 15px !important;
    height: unset !important;
}

.rolelabel {
    float: left;
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 23px;
}

.manage-user  .user-section4 #city {
    width: 27% !important;
}
 
.manage-user  .user-section4  #country  {
    width: 49% !important;
}

.adduserstatement{
    width: 100%;
    float: left;
    text-align: left;
    margin-top: 10px;
    padding-left: 3%;
}



/* below lines are for style of add/edit customer popup */
.customer-section1 {
    width: 100%;
    float: left;
    padding: 10px 20px;
}

.customer-section4 {
    clear: left;
}
 .customer-section6,
 .customer-section5,
 .customer-section4,
 .customer-section2,
 .customer-section3  {
    width: 50%;
    float: left;
    padding: 10px 20px;

}
.customer-section6 input,
.customer-section5 input,
.customer-section4 input,
.customer-section2 input,
.customer-section3 input {
    float: left;
}

 
.create-customer .customer-section2,
.create-customer .customer-section3,
.create-customer .customer-section4,
.create-customer .customer-section5,
.create-customer .customer-section6,
.create-customer .customer-section1{
 width: 100% !important;
 padding: 0px 15px !important;
}

.create-customer {
    margin-top: -32px;
}

.create-customer .customer-section2 input, 
.create-customer .customer-section3 input, 
.create-customer .customer-section4 input, 
.create-customer .customer-section5 input,
.create-customer .customer-section1 input,
.create-customer .customer-section6 input,
.create-customer  select {
    padding: 8px 15px !important;
    height: unset !important;
}

.rolelabel {
    float: left;
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 23px;
}

.create-customer  .customer-section5 #city {
    width: 27% !important;
}
 
.create-customer  .customer-section5  #country  {
    width: 49% !important;
}

.create-customer  .customer-section6  #email  {
    width: 96% !important;
}

.create-customer .popup-btn {
    text-align: right;
    padding-right: 2%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .manage-user .user-section4 #country {
        width: 46%!important;
    }
    .create-address-main-div {
        width: 100%;
        float: left;
        margin-top: 40px;
    }
    .create-customer .customer-section5 #country {
        width: 46%!important;
    }
    .popup-container {
        width: 100%;
    }
}


/* Meeting room popup styles */
.message-popup-place{
    width: 20%;
    margin: 0 9% 0 62% !important;
}
.popup-place {
    width: 33%;
    margin: 0 9% 0 56% !important;
}
.closeIcon-width{
    width: 12px;
    height: 12px;
    align-self: center;
}
.user-selected{
    width: auto;
    background-color: #E6E6E6;
}
.click-box{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: ' ';
}
.timepicker{
    width: 50% !important;
    position: absolute;
    right: 15px;
    top: 25px;
    z-index: 999;
    border: 0.25px solid lightgray;
}
.popup-input{
    display: inline-block;
}

@media screen and (min-width:577px) and (max-width:992px) {
    .popup-place-mob{
        width: 50%;
        margin: auto !important;
    }
    .message-popup-mob{
        width: 50%;
        margin: auto !important;
    }
}

@media screen and (max-width:576px) {
    .popup-place-mob{
        width: 90%;
        margin: 5% auto !important;
    }
    .message-popup-mob{
        width: 80%;
        margin: 5% auto !important;
    }
    .timepicker{
        width: 45% !important;
        position: absolute;
        right: 15px;
        top: 25px;
        z-index: 999;
        border: 0.25px solid lightgray;
    }
}
.popupborder {
    border: 1px solid #d20c14 !important;
    /* border-radius: 0 !important; */
}
.meeting-date-picker .react-datepicker-wrapper{
    width: 100% !important;
    float: left;
  }

.popup-image{
    margin: 15% 42% 5% 42%;
    width: 40px;
}
.user-box{
    overflow: auto;
    max-height: 70px;
}

/* .filter-popups .addscroll{
    max-height: 50px !important;
    overflow-y: auto;
} */
/* filter-popups .addscroll {
    max-height: 50px !important;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-right: 2rem !important;
  } */
  .filter-popups .addscroll {
    padding-right: 0px;
    margin-right: 2rem !important;
    overflow-y: scroll;
    max-height: 50px;
    padding-left: 9px !important;
  }
  .css-g1d714-ValueContainer:focus
  {
    border: 1px solid red;
  }
  .range
  {
    height: 2.5rem;
  }
  /* .css-1pahdxg-control:hover,
  .css-1pahdxg-control:focus,
  .css-2b097c-container:hover,
  .css-2b097c-container:focus
  {
    border-color: red !important;
  } */
  /* .testingDataMeetingRoom:hover,
  .testingDataMeetingRoom:focus
  {
    border-color: red !important;
  } */