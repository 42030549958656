.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: brandon-grotesque,sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #F9F9F9;
  overflow-x: hidden !important;
}

.content {
  padding-bottom: 25%;
  width: 100%;
  float: left;
}

.filter-parent{
  /* display: flex; */
  justify-content: end;
}
.filter-parent button{
  margin-right: 0px;
  background: #d20c14 !important;
}

/* iphoneX */

@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 2) {
   .content {
     padding-bottom: 20% !important;
    }
}

.toast-header {
  padding-left: 3px !important;
}

@media screen and (min-width: 1280px)  {
  body {
    overflow: hidden;
  }
}

.preview-text {
  position: relative;
}

.preview-text h1 {
  position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 165px;
    transform: rotate(-45deg);
    font-size: 12rem;
    color: #ddd;
    z-index: 9;
}
.unauthorisedAccess hr{
  margin:auto;
  width:50%;
  border-color:rgb(231, 12, 12)!important;
  margin-bottom: 20px;
}

.unauthorisedAccess h1{
  text-align: center;
  font-size: 64px!important;
  color: red;
  margin-bottom: 20px;
}

.unauthorisedAccess h3{
  text-align: center!important;
}

.capacity_margin
{
  margin-left: 18px !important;
}
form.filter-popups label
{
  margin-top: 14px !important;
}
/* .filter-popups label{
  top:15px;
} */
/* .filter-popups .capacity-value {
  margin-left: 17px;
} */

@media screen and (min-width: 0px) and (max-width: 767px) {
  .preview-text h1 {
    font-size: 98px !important;
  }
  .filter-popups .addscroll {
    padding-left: 45px !important;
  }
  div#filter-multiselect
  {
    padding: 10px 0px !important;
  }
  .capacity-value {
    padding: 10px 0px;
  }
  .capacity_margin
  {
    margin-left: 0px !important;
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .preview-text h1 {
    font-size: 10rem !important;
  }
}

.activeItem{
  background-color: rgba(154, 152, 152, 0.4);
  /* border-bottom: 2px solid #d20c14 !important; */
}
/* .activeItem .card-title,
.activeItem .card-text span{
  color: #d20c14 !important;
} */
  .filter-popups .addscroll {
    padding-right: 0px;
    margin-right: 2rem !important;
    overflow-y: scroll;
    max-height: 50px;
    padding-left: 18px !important;
  }
  .capacity_margin
  {
    margin-left: 14px !important;
  }



  /*html, body {
	    touch-action: none;
    }
    */
    
    
