.Filter-button {
  color: #d20c14 !important;
  font-size: 13px;
}

.contact-1 {
  color: #d20c14 !important;
}

.whoiswho-search input{
    height: unset !important;
    padding: 10px 10px 10px 35px !important;
    border: 1px solid #d20c14 !important;
    /* border-radius: 0 !important; */
    background-color: transparent;
    background-image: url('../../Static/images/search.png');
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 9px 14px;
}

.imagesection img {
  max-width: 100%;
  width: 80px;
  height: 80px;
}

.hide-class{
  display: none;
  color: yellow;
}

.Filter-button {
  font-size: 15px;
}
.who-card {
  background: none;
  border: 0px;
}

.descsize {
    /* font-size: 12px; */
    display: flex;
    align-items: center;
    color: #3d3d3da6;
}

.descsize svg {
  width: 17px;
}

.whoicons svg {
    font-size: 18px;
    margin-right: 8.5px;
}

.whoicons{
  display: flex;
  justify-content: center;
  align-content: flex-end;
  align-items: flex-end;
  padding-bottom: 7px;
  padding-left: 15px;
}

.disable-class{
  color: gray;
  background: none;
  border: none;
  pointer-events: none;
}


/* A-Z popup */
.azpopup {
  width: 75%;
  margin: auto;
}
.azpopupinner {
  height: 470px;
  padding: 9% 12% 0;
}

.azpopupinner .sectionchar {
    width: 25%;
    float: left;
    margin: 10px 0;
}

.azpopupinner .sectionchar a {
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 50%;
  background: #fff;
  font-size: 20px;
  outline: 0;
  -webkit-appearance: unset !important;
}

.azbutton {
  -webkit-appearance: unset !important;
  padding-top: 9px;
}

.azbutton h1 {
  font-size: 19px;
  margin-bottom: 0px;

}

/* .azpopupinner .sectionchar a:hover {
  background-color: #d20c14 !important;
  color: #fff;
} */

.azpopupinner .sectionchar a:link, 
.azpopupinner .sectionchar a:visited, 
.azpopupinner .sectionchar a:hover, 
.azpopupinner .sectionchar a:active {
  background-color: #d20c14 !important;
  color: #fff;
  border: 1px solid transparent !important;
}

.wswicons{
  width: 32px;
  height: 30px;
  background-size: 47%;
  background-repeat: no-repeat;
  background-position: center;
}

.whatsAppinner {
  background-position: 12px center !important;
}

.emailactive p {
  background-image: url('./images/Email-active.png');
}

.emaildisable p{
  background-image: url('./images/Email_disable.png');
}

.whatsappdisable p{
  background-image: url('./images/Whatsapp_disable.png');
  background-position: 9px center;
}

.whatsapactive p{
  background-image: url('./images/Whatsapp_active.png');
  background-position: 9px center ;
}

.activephone p{
  background-image: url('./images/Call_active.png');
}

.disbalephone p{
  background-image: url('./images/Call_disable.png');
}

.innericon {
  width: 40px !important;
  background-size: 45% !important;
}


.flag-phone input[type="tel"]{
  background: transparent;
    border: 0px;
    padding: 0px 0px 0px 35px;
    font-size: 15px;
}

.flag-phone .flag-dropdown{
  text-align: center;
  border-radius: 0px !important;
  border: 0px !important;
  box-shadow: none !important;
  outline: 0 !important;
  background: transparent;
}

.flag-phone .react-tel-input .selected-flag .arrow {
  display: none;
}
.layoutSpace{
  margin-left: 40px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .desktopdiv {
    display: none;
  }

}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .desktopdiv {
    display: none;
  }
}

@media screen and (min-width: 1281px) {
  .mobilediv{
    display: none;
  }
  .desktopdiv .desk-avatar .ant-avatar-image img {
    width: 100px !important;
    height: 100px !important;
  }
  .bottom-margin {
    margin-bottom: 2px !important;
  }
  .innericon {
    background-size: 38% !important;
  }
}


@media screen and (min-width: 0px) and (max-width: 767px) {
  @supports (-webkit-touch-callout: none) {
    .ant-avatar-image{
      width: 92px !important;
      height: 100px !important;
    }
    .ant-avatar > img {
      position: absolute !important;
      top: 10px !important;
      left: 10px !important;
    }
  }
  
  @supports not (-webkit-touch-callout: none) {
    
    .ant-avatar > img {
      position: absolute;
      top: 10px !important;
      left: unset  !important;
  }
  .ant-avatar-image{
    width: 92px !important;
    height: 100px !important;
  }
  }
}
.ant-avatar > img {
  position: absolute;
  top: 0px !important;
  left: unset  !important;
}
.ant-avatar-image{
width: 92px !important;
height: 100px !important;
}

.top_aligner {
  top: 2px !important;
}

.activeItem{
  background-color: rgba(154, 152, 152, 0.4);
  /* border-bottom: 2px solid #d20c14 !important; */
}