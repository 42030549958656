.overlay-wrapper {
  position: absolute;
  z-index: 6;
  width: 28%;
  margin-right: 7%;
  margin-top: -2px;
  right: 0px;
}
.calender-button {
  width: 136px !important;
}

.schedular-detail-page .datepicker-navbar{
  border-top: none !important;
}

.schedular-detail-page .datepicker-navbar a:first-child{
  background: #d20c14!important;
  color: #fff!important;
  border-radius: 3px;
  height: 1.75em !important;
  line-height: 1.75em !important;
  margin: 3px 5px 3px 25px;
}

.schedular-detail-page .datepicker-navbar a:last-child{
  border-color: #6c757d!important;
  background-color: #6c757d!important;
  color: #fff;
  border-radius: 3px;
  height: 1.75em !important;
  line-height: 1.75em !important;
  margin: 3px 25px 3px 5px;
}

.schedular-container{
  margin-top: 3%;
}
.meeting-location {
  font: normal normal normal 20px/30px brandon-grotesque, regular !important;
}

.meeting-room {
  color: "#C30C12";
  font:normal normal bold 21px brandon-grotesque, regular !important;
}
.meeting-date {
  font: normal normal normal 15px/19px brandon-grotesque, regular !important;
}

.rbc-time-view{
  border: none !important;
}
.rbc-time-header{
  display: none;
}

.rbc-timeslot-group{
  min-height: 9vh !important;
  /* border-bottom: none !important; */
}
.rbc-day-slot{
  border-top: 1px solid #DDD !important;
}
.rbc-day-slot .rbc-time-column{
  border-top: 1px solid #DDD !important;
}
.rbc-time-gutter .rbc-timeslot-group{
  border-bottom: none !important;
}
.rbc-time-content{
  border-top: none !important;
}
.rbc-time-content > * + * > *  {
  border-left: none !important;
  border-top: none !important;
}
.rbc-time-content::-webkit-scrollbar {
  width: 5px; 
} 
.rbc-time-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}

.rbc-time-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.rbc-time-content::-webkit-scrollbar-thumb:hover {
  background: #ddd;
  padding-top: 20px;
}
.rbc-timeslot-group{ 
  background-color:#F8FAFC; 
}
.rbc-calendar{
  height: 80vh !important;
}
.scan-box div{
  box-shadow: none !important;
  background-image: url('../../Static/images/qr-code-scan_3.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none !important;
  background-size: cover;
}

.scan-box section{
  padding-top: 104% !important;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .overlay-wrapper {
    width: 41%;
    margin-right: 10%;
  }
  .meeting-location {
    font: normal normal normal 16px/24px brandon-grotesque, regular !important;
  }
  
  .meeting-room {
    color: "#C30C12";
    font:normal normal bold 16px/24px brandon-grotesque, regular !important;
  }
  .meeting{
    font: normal normal normal 18px/26px brandon-grotesque, regular;
  }
  .rbc-calendar{
    height: 86vh !important;
  }
  .schedular-detail-page .datepicker .datepicker-header {
    padding: 0 .5em;
    min-height: 1em;
    line-height: 1em;
    font-size: 0.125em;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .overlay-wrapper {
    width: 30%;
    margin-right: 18%;
  }
  .meeting{
    font: normal normal normal 18px/26px brandon-grotesque, regular;
  }
  .meeting-date {
    font: normal normal normal 14px/19px brandon-grotesque, regular !important;
  }
  .rbc-calendar{
    height: 70vh !important;
  }
  .rbc-timeslot-group{
    min-height: 10vh !important;
    /* border-bottom: none !important; */
  }
}

@media screen and (max-width: 320px) {
  .mobile-responsive{
    flex: 0 0 81% !important;
    max-width: 81% !important;
  }
  .meeting-date {
    font: normal normal normal 13px/17px brandon-grotesque, regular !important;
  }
  .meeting{
    font: normal normal normal 17px/25px brandon-grotesque, regular;
  }
}
.rbc-current-time-indicator {
  background-color: #d20c14 !important;
  height: 2px !important;
}