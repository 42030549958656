
hr.solid {
    border-top: 1px solid #ddd;
    text-align: center;
}
.profile-image-div {
    margin-top: -39px;
}
.hide-class{
    display: none;
    /* color: yellow; */
  }

.css-1pahdxg-control:hover{
    border-color: #d20c14 !important;
}
.css-1pahdxg-control{
    border-color: #d20c14 !important;
    box-shadow: none !important;
}

.profileTab {
    border: 0;
    display: flex;
    align-items: stretch!important;
    justify-content: center;
    align-content: center;
}

.profileTab .nav-item {
    display: flex;
}

.profileTab li .switchtab {
    padding: 15px 15px !important;
    border: 1px solid #d20c14 !important;
    border-radius: 0 !important;
    color: #d20c14 !important;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.profileTab li .switchtab.active {
    background-color: #d20c14 !important;
    color: #fff !important;
    font-weight: bold;
}

.profileTab li div {
    cursor: text !important;
}

.contactdetail input,
textarea  {
    border: 1px solid #ddd !important;
    border-radius: 0px !important;
    padding: 13px 13px !important;
    height: unset !important;
    font-size: 18px !important;
}

.countyNumber input{
    padding: 13px 13px 13px 50px !important;
    width: 100% !important;
}


.MuiInput-underline:after {
    border-bottom: 2px solid #d20c14 !important;
}

.MuiInputLabel-formControl {
    top: 9px !important;
    left: 13px !important;
}

.MuiFormLabel-root.Mui-focused {
    top: -15px !important;
    left: 2px !important;
    font-size: 18px !important;
    color: #3D3D3D !important;
    margin-top: 10px !important;
}

.MuiFormControl-fullWidth {
    margin: 10px 0;
} 

.MultiChipSelect-chipContainer-1 .MuiChip-root {
    background-color: #fff !important;
    border: 1px solid #ddd;
    padding: 17px 6px;
}

.profilenum {
    font-size: 17px !important;
}

.countyNumber {
    padding: 13px 0 13px !important;
}

.actionButton{
    /* white-space: nowrap; */
    /* margin: 0 !important; */
    font-size: 18px;
    border-radius: 0px;
    padding: 10px 10px 10px !important;
}

.actionButton-left{
    width: 45%;
}

.actionButton-left  {
    background: #545960;
    color: #fff;
}

.actionButton-right{
    width: 45%;
}
.desktopactionButton-right{
    width: 30%;
}
.desktopactionButton-left{
    width: 30%;
    background: #545960;
    color: #fff !important;
}

.flag-phone input[type="tel"]{
    background: transparent;
      border: 0px;
      padding: 0px 0px 0px 35px;
      font-size: 15px;
  }
  
  .flag-phone .flag-dropdown{
    text-align: center;
    border-radius: 0px !important;
    border: 0px !important;
    box-shadow: none !important;
    outline: 0 !important;
    background: transparent;
  }
  
  .flag-phone .react-tel-input .selected-flag .arrow {
    display: none;
  }

  .bday {
    font-size: 16px !important;
  }

 .edit-button {
    margin-top: 7px;
    padding-left: 0 !important;
  }

  .lan-select #react-select-2-input {
      width: 100% !important;
      border: 0px solid !important;
  }

  @media screen and (min-width: 1280px) {
      #personaldetail label,
      .contactdetail  label{
            font-size: 15px !important;
      }
      .profileTab {
        width: 56% !important;
        margin: auto;
      }
      .desktop-save button {
        width: 30% !important;
        /* float: right; */
      }
      .contactdetail input, textarea {
        padding: 8px 13px 4px 10px !important;
      }
      .css-yk16xz-control,
      .css-g1d714-ValueContainer  {
          min-height: 20px !important;
          border-radius: 0px !important;
      }
      .css-1hb7zxy-IndicatorsContainer{
        align-self: self-start !important;
      }
      .css-b8ldur-Input input,
      .css-yk16xz-control input,
      .css-1g6gooi input {
        border: 0px solid !important;
        width: 100% !important;
        padding: 0 !important;
    }
  }

  @media screen and (min-width: 320px ) and (max-width: 767px) {
    .mob-fontsize {
        font-size: 17px !important;
    }
  }