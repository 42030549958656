
.thumbnail{
    height: 100px;
    text-align: center;
    width: 100%;
}

.detailrating {
    font-size: 11px;
}

.detailrating .star {
    width: 39px !important;
    height: 30px;
    float: left;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 27px 3px !important;
    margin-right: 0px;
}

.rating {
    margin-left: 75px;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 100%;
    padding: 10px 16px;
    color: white;
    content: "Browse";
    background-color: #d20c14;
    border-left: inherit;
    border-radius: 0px;
}

.tipsWrap .card-title {
    height: auto !important;
    margin-bottom: 5px !important;
}

.detailstar {
    background-position: 6px 9px !important;
}

.detaillocation {
    background-size: 15px !important;
    /* background-position: left center !important; */
    background-position: 3px 4px !important;
    width: 17px !important;
    margin-bottom: 20px;
    /* margin-right: 11px !important; */
}

.detailgetthere{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    white-space: nowrap;
}

.websiteurl {
    display: inline-block;
    margin: 10px 0 0;
}

.itemrate .MuiRating-iconFilled svg {
    color:  #d20c14 !important;
}

.itemrate .MuiRating-iconEmpty svg {
    color: rgba(0, 0, 0, 0.1) !important;
}

.addlunchlable {
    font-size: 18px;
}

.fieldsWrapp input[type="text"] {
    height: unset !important;
    padding: 10px 10px !important;
    border: 1px solid #ddd !important;
    border-radius: 0 !important;
}

.fieldsWrapps input[type="text"] {
    height: unset !important;
    padding: 0px !important;
    border: 0px #ddd !important;
    border-radius: 0 !important;
}

input#search_input {
    width: 100%;
    margin: 0 !important;
}

._2iA8p44d0WZ-WqRBGcAuEV {
    padding: 0 !important;
    border: 0px solid !important;
    background: #fff !important;
    margin-top: 10px !important;
}

#file {
    width: 100% !important;
    background: #fff !important;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 45px;
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0px;
}

#response > div,
#result > div {
    display: inline-block;
    border: 1px solid #ddd;
    width: 30%;
    margin-right: 16px;
}

#response > div:nth-child(3),
#response > div:nth-child(6),
#response > div:nth-child(9),
#result > div:nth-child(3),
#result > div:nth-child(6),
#result > div:nth-child(9)  {
    margin-right: 0 !important;
}

.word-break {
    word-break: break-word;
}

.inner-foodtype{
    background-image: url('./redlunchtip.png') !important;
    background-size: 14px !important;
    width: 17px !important;
}

.map-border-bottom {
    border-bottom: 1px solid #ddd !important;
    padding-bottom: 13px;
}

.ratingSection{
    border-top: 1px solid #ddd;
}

.search-lunchtip input {
    border-radius: 4px !important;
}

.weburl a:hover {
    color : #d20c14;
}

.disableLink {
    color: #545960;
    pointer-events: none;
  }

  .listlunchtoipicon {
      height: 20px !important;
      background-size: 11px !important;
  }

  .goButton{
    margin-top: 33px;
    text-align: center;
  }

.listRate{
    margin-top: 2px;
  }

  .tipsRatings {
    display: flex;
    flex-direction: column;
    height: 70px;
  }

 .rateSection {
    width: 100%;
    float: left;
    display: inline-block !important;
    margin: 15px 0 7px;
  }

  .rateSave{
    margin: 0 !important;
  }

  .addtipssearch input {
    border-color: #ddd !important;
    border-radius: 0 !important;
    background-color: #fff !important;
  }

  /* .rate-center-align {
      width: 46%;
      margin: auto;
  } */

  .rate-center-align {
    width: 150px;
    margin: auto;
}

  @media only screen and (min-width: 1025px){
    .rate-center-align {
        width: 150px;
        margin: auto;
    }
  }

  .rate-center-align .MuiRating-icon {
      display: unset !important;
  }

 .rating-message {
    text-align: right;
    padding-right: 10px;
  }

  @media screen and (min-width: 1300px)  {
    .desktoppopup {
        width: 35% !important; 
        
    }

    .desktoppopup .modal-content{
        border-radius: 0px;
        padding: 0px 5px;
    }

    .desktoppopup .close {
        text-align: right;
        outline: 0 !important;
    }
    .desktoppopup .modal-header,
    .desktoppopup .modal-body,
    .desktoppopup .modal-footer {
        border: 0px solid !important;
        outline: 0 !important;
    }

    .desktoppopup .modal-footer button {
        border: 1px solid #d20c14 !important;
        border-radius: 0 !important;
    }
  }