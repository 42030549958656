.contentWrap {
    position: relative;
    height: 120px;
    width: 102px;
    margin: 0 auto 17px;
}

.contentoverlay {
    position: absolute;
    background: #8b8b8b;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    border-radius: 4px;
}

.contentWrap .libicons {
    position: relative;
    z-index: 99;
    width: 35px;
    margin: auto;
    text-align: center;
    left: 30px;
    top: 28px;
}

.caption {
    position: absolute;
    z-index: 99;
    bottom: 0;
    width: 100%;
    padding: 8px 7px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.caption h5 {
    color: #fff;
}

.postclistlibrary{
    /* width: 100%; */
    float: left;
    padding-right: 0;
}

.libinnerdetail {
    left: 32px !important;
    top: 32px !important;
}

img.libicons.libinnerdetail.image {
    top: 0 !important;
    width: 100% !important;
    left: 0 !important;
    height: 100% !important;
    padding: 5px;
}
img.libicons.libinnerdetail.libicons-thumbnail {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
}
.itemsWrap {
    float: left;
    width: 33.33%;
}

@media screen and (min-width: 1280px)  {
    .itemsWrap {
        float: left;
        width: auto !important;
        margin: 0 8px;
    }
    .contentWrap {
        width: 107px;
    }
    .libinnerdetail {
        left: 35px !important;
    }
    .itemsWrap:nth-child(4),
    .itemsWrap:nth-child(8),
    .itemsWrap:nth-child(12),
    .itemsWrap:nth-child(16),
    .itemsWrap:nth-child(20),
    .itemsWrap:nth-child(24),
    .itemsWrap:nth-child(28) {
        margin-right: 0 !important;
    }
    .Desktop-mode-list-and-detail-view .container.pl-0.py-3.mt-2{
        width: 99%;
        float: left;
        padding: 0;
    }
    .postclistlibrary {
        margin: 0 !important;
    }

    .detail-Section .contentWrap {
        width: 100% !important;
    }
    .detail-Section .libinnerdetail {
        left: 55px !important;
        top: 40px !important;
    }
}

.react-pdf__Page__svg {
    margin: auto !important;
}

.highlight-activeItem{
    border-style: solid;
    border-color: #d20c14;
}