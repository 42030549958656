.datepicker {
  position: unset !important;
}

.datepicker.android .datepicker-header,
.datepicker.android-dark .datepicker-header {
  color: rgba(0, 0, 0, 0.85) !important;
  border-bottom: none !important;
}

.datepicker .datepicker-viewport {
  height: 113px !important;
}

.datepicker.android .datepicker-wheel,
.datepicker.android-dark .datepicker-wheel {
  border-top: 2px solid #dddddd !important;
  border-bottom: 2px solid #dddddd !important;
}
.datepicker .datepicker-content {
  padding-top: 0px !important;
}
.datepicker.android,
.datepicker.android-dark {
  font-size: 13px !important;
  background-color: #ffffff !important;
}
.datepicker.android .datepicker-viewport::after,
.datepicker.android-dark .datepicker-viewport::after {
  background-image: linear-gradient(
    #ffffff,
    rgba(245, 245, 245, 0) 52%,
    rgba(245, 245, 245, 0) 48%,
    #ffffff
  ) !important;
}
.title_sytle {
  width: 100% !important;
  left: 0% !important;
}