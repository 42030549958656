.App {
  font-family: sans-serif;
  text-align: center;
}

/* h4 {
  text-align: left;
  margin-left: 40px;
}

hr {
  border-top: 2px dotted #3f51b5;
} */

button {
  margin-right: 10px;
  background: #3f51b5;
  color: white;
  border: none;
  padding: 5px 10px;
  width: 100px;
  cursor: pointer;
  box-shadow: 2px 2px 2px 1px #ccc;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.document-pdf {
  overflow-y: auto;
  height: 89vh;
  overflow-x: hidden;
  width: 98%;
  margin-left: auto;
}

#placeholderWrapper{
    width: 3vh !important;
}

/* @media screen and (min-width: 1600px) and (max-width: 1920px) {
  .document-pdf{
    height: 100vh !important;
    max-height: unset !important;
  }
} */

.react-pdf__Page__annotations.annotationLayer {
  padding: 0 !important;
}