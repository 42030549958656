.avatar-save {
    background: #d20c14 !important;
}
.cancel-btn-avatar {
    background-color: #545960 !important;
}
.avatar-btn {
    border-radius: 0px !important;
    font-family: brandon-grotesque,sans-serif !important;
}
g[mask="url(#mask-2)"] {
    fill: #545960 !important;
}
.sc-jSgupP.YrJoZ svg {
    width: 120px !important;
    height: 120px !important;
} 

.sc-jSgupP.YrJoZ {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 40px;
}

.ePviCp {
    background: none !important;
    margin: 0;
    padding: 0;
}

.sc-dlfnbm {
    margin: 7px;
}

.left-btn-avatar,
.right-btn-avatar {
    color: #d20c14 !important;
}

.selecton-header {
    border-bottom: 1px solid #ccc;
}

.left-btn-avatar {
    padding-left: 0 !important;
}

.right-btn-avatar {
    padding-right: 0 !important;
}

.sc-fubCfw.cCtXxU {
    width: 100%;
    float: left;
    margin: 25px 0 35px;
}

.hXBLM:hover{
    background-color: #ddd !important;
}
 

@media screen and (min-width: 1025px) {
    .avatar-btn {
        padding: 6px 0 !important;
        font-size: 14px !important;
    }
    .sc-hKgILt {
        border-radius: 50%;
        width: 17px !important;
        height: 17px !important;
        margin-bottom: 5px;
    }
    .ePviCp {
        width: 100% !important;
    } 
    
    .jnSeWy {
        width: 20.5% !important;
        float: left !important;
        position: absolute !important;
        left: 0 !important;
        top: 150px !important;
        border-right: 1px solid #ccc !important;
        z-index: 9;
    }
    
    .sc-jrAGrp.khwMRd {
        flex-direction: column;
        position: absolute;
        right: -45px;
        width: 45px;
        background: #f3f3f3;
        padding: 4px 10px 0;
        top: 0;
        height: 100%;
        border-left: 1px solid #cccccc59;
    }
    
    .sc-iBPRYJ.edMCxs {
        padding: 10px 10px;
        width: 490px !important;
        position: relative;
        background: #f3f3f3;
        margin-top: -10px;
        height: 345px;
    }
      
    .sc-gKsewC {
        background: #f3f3f3 !important;
        color: #333 !important;
        border: 1px solid transparent !important;
        border-radius: 0px !important;
        padding: 9.6px !important;
        font-size: 15px !important;
        border-bottom: 1px solid #ccc !important;
    }
    
    .sc-gKsewC.zLMOP {
        background-color: #d20c14 !important;
        color: #fff !important;
    }
      
    .sc-hKgILt {
        border-radius: 50%;
        width: 17px !important;
        height: 17px !important;
        margin-bottom: 5px;
        margin-bottom: 5px;
    }
    
    .avatar-section {
        width: 100%;
        float: left;
        height: 100vh;
    }
    
    .sc-gsTCUz.ePviCp {
        margin: 0 auto !important;
        padding: 0 !important;
        width: 650px !important;
    }
    
    
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .sc-kEjbxe.dmhDzb {
        position: relative;
    }
    .sc-jrAGrp.khwMRd {
        width: 100%;
        float: left;
        position: absolute;
        bottom: 5px;
    }
    .sc-iBPRYJ.edMCxs { 
        height: 510px !important;
    }
    .sc-hKgILt { 
        width: 22px !important;
        height: 22px !important;
        border-radius: 50%;
        margin: 4px 5px;
    }
    .sc-gsTCUz.ePviCp,
    .sc-kEjbxe.dmhDzb,
    .sc-iBPRYJ.jzdcwR,
    .sc-iBPRYJ.edMCxs {
        width: 100% !important;
    }
    .myform .customContainer {
        padding: 0 !important;
    }

    .myform .btn-group {
        padding: 0 !important;
    }
    .sc-jSgupP.YrJoZ {
        padding: 0 !important;
    }
    .sc-kEjbxe.dmhDzb {
        background-color: #f3f3f3;
    }
    .myform .btn-group {
        height: 61px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f3f3f3;
        padding: 0 10px !important;
    }
    .sc-iBPRYJ.edMCxs {
        padding: 10px 0 15px !important;
    }
    .location-address {
        width: 100%;
        float: left;
    }
    .avatar-btn{
        padding: 14px 50px !important;
        font-size: 16px !important;
        width: auto !important;
    }
}