.play-button:after {
  content: "";
  display: block;
  position: absolute;
  top: 16.5px;
  left: 40px;
  margin: 0 auto;
  border-style: solid;
  border-width: 12.5px 0 12.5px 20px;
  border-color: transparent transparent transparent rgba(255,255,255,1);
}

.play-button {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 60px;
  width: 100px;
  background-color: rgba(0,0,0,.7);
  border-radius: 5px;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  display: none;
}

.image-gallery-index {
  display: none;
} 


.image-gallery-bullets .image-gallery-bullet {
  width: 10px;
  height: 10px;
  margin-top: 1px;
}

.image-gallery-bullets .image-gallery-bullet:first-child,
.image-gallery-bullets .image-gallery-bullet:last-child {
  width: 8px;
  height: 8px;
}

.image-gallery-bullets .image-gallery-bullets-container {
  display: flex;
  justify-content: center;
  align-items: center;
}