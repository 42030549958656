.calender .react-datepicker,
  .calender .react-datepicker__month-container,
  .calender .react-datepicker__header {
    width: 100%;
    border: 0px;
    background: none;
  }

  .calender .react-datepicker__day-name, 
  .calender .react-datepicker__day, .react-datepicker__time-name {
    width: 1.5rem;
    margin: 3px 7.5px;
    font-size: 14px;
    line-height: 34px !important;
    width: 33px !important;
    height: 33px !important;
  }

  .react-datepicker__day--selected {
    background-color: #d20c14 !important;
    border-radius: 50% !important;
    padding: 1px 6px 0px 5px !important;
    font-weight: bold;
}

  .calender .react-datepicker__current-month, 
  .calender .react-datepicker-time__header, 
  .calender .react-datepicker-year-header {
    color: #3D3D3D;
    font-weight: normal !important;
    margin-bottom: 14px;
  }

  .calender .react-datepicker__day--outside-month {
    visibility: hidden;
}


.calender .react-datepicker__navigation--previous {
 background-image: url('../../../src/Static/images/left-arrow.png');
 border: 0px !important;
 background-repeat: no-repeat;
 height: 20px;
 background-size: 13px;
 padding-left: 10px;
 outline: 0;
}

.calender  .react-datepicker__navigation--next {
  background-image: url('../../../src/Static/images/next-arrow.png');
  border: 0px !important;
  background-repeat: no-repeat;
  height: 20px;
  background-size: 13px;
  padding-right: 10px;
  outline: 0;
}

.react-datepicker__day--highlighted, 
.react-datepicker__month-text--highlighted, 
.react-datepicker__quarter-text--highlighted, 
.react-datepicker__year-text--highlighted,
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, 
.react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected {
  background: none !important;
  color: #333333;
  position: relative;
  border-radius: 0 !important;
  outline: 0;
}

.react-datepicker__day--highlighted::after, 
.react-datepicker__month-text--highlighted::after, 
.react-datepicker__quarter-text--highlighted::after, 
.react-datepicker__year-text--highlighted::after {
  content: '';
    position: absolute;
    height: 1px;
    width: 15px;
    background: #d20c14;
    left: 0;
    bottom: 3px;
    right: 0;
    margin: auto;
}

.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, 
.react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year-text--in-range,
.react-datepicker__day--today, 
.react-datepicker__month-text--today, 
.react-datepicker__quarter-text--today, 
.react-datepicker__year-text--today,
.react-datepicker__day--selected.react-datepicker__day--highlighted {
  color: #d20c14;
  position: relative;
}

.react-datepicker__day-name {
  color: #989DB3;
  font-size: 12px !important;
}
 
.react-datepicker__day--in-selecting-range::after, 
.react-datepicker__day--in-range::after, 
.react-datepicker__month-text--selected::after, 
.react-datepicker__month-text--in-selecting-range::after, 
.react-datepicker__month-text--in-range::after, 
.react-datepicker__quarter-text--selected::after, 
.react-datepicker__quarter-text--in-selecting-range::after, 
.react-datepicker__quarter-text--in-range::after, 
.react-datepicker__year-text--selected::after, 
.react-datepicker__year-text--in-selecting-range::after, 
.react-datepicker__year-text--in-range::after,
.react-datepicker__month-text--today::after, 
.react-datepicker__quarter-text--today::after, 
.react-datepicker__year-text--today::after,
.react-datepicker__day--selected.react-datepicker__day--highlighted::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 15px;
  background: #d20c14;
  left: 0;
  bottom: 2px;
  right: 0;
  margin: auto;
}

.react-datepicker__day--today {
  background-color: transparent !important;
  outline: 0;
}


 .react-datepicker__day--selected.react-datepicker__day--highlighted {
  background-color: #d20c14 !important;
    border-radius: 50% !important;
    padding: 1px 6px 0px 5px !important;
    font-weight: bold;
    color: #fff;
}
/*
.react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--today{
  background-color: #d20c14 !important;
  border-radius: 50% !important;
  padding: 1px 6px 0px 5px !important;
  font-weight: bold;
  color: #fff;
} 
*/
.react-datepicker__today-button {
  width: 113px;
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  color: #d20c14;
  border-radius: 5px;
  border: 1px solid #d20c14;
  font-size: 14px;
  padding: 3px 10px 0px;
  font-weight: normal;
  line-height: 31px;
  float: right;
  margin-right: 15px;
}

.calender .react-datepicker__month-container {
  margin-bottom: 30px;
}

.react-datepicker {
  position: relative;
}