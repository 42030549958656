.navbar {
    /* border-bottom: solid 1px #EBEBEB; */
  }
  .bottom-tab-nav {
    border-top: solid 1px #EBEBEB;
  }
  .nav-link,
  .bottom-nav-link {
    color: #55575b;
  }
  .bottom-nav-link.active {
    color: #922c88;
  }
  .bottom-tab-label {
    font-size: 12px;
  }
  .tab-icon, .tab-title{
    color: none;
    border: white;
  }
  .active, .nav-item a:hover {
    background-color:transparent;
  color: white;
  }

  .floating-menu-custom{
    position: fixed;
    right: -2px;
    width: 100%;
    bottom: 80px;
    z-index: 9999;
    
  }

  .floating-menu-custom .footer li:last-child ~ div {
    display: none;
  }

  .roundbg {
    width: 55px !important;
    height: 55px !important;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    box-sizing: border-box;
    border-radius: 50%;
  }

  .humburgerMenu {
    transition: all 500ms ease 0s;
  }

  .bg-color {
    background: #d20c14 !important;
    color: #fff !important;
}

.textcolor {
  color: #d20c14 !important;
}


.animated {
  filter: blur(100px);
    pointer-events: none !important;
    overflow: hidden !important;
}

.animated:parent:parent {
  display: none !important;
}

.MuiButton-root {
  text-transform: unset !important;
  font-family: unset !important;
}

  #loading-icon {
    position: absolute;
    float: left;
    top:50%;
    left:0;
    right: 0;
    text-align: center;
    margin: auto;
}

.iconSection{
  display: flex;
    justify-content: flex-end;
}

.MuiFab-root {
  width: 48px !important;
  height: 48px !important;
}

.footericons {
  width: 25px;
  height: 25px;
}

.nav-item img[alt="location"] {
  width: 21px !important;
}

.flaotMenus img[alt="profile"]{
  width: 21px !important;
}

.flaotMenus:nth-child(3) img {
    /* width: 26px;
    height: 18px;
    margin-top: 3px;
    margin-left: 4px; */
}
.Notification-BadgeCount div span {
  z-index: 1;
}

.badgeDesign > div > span {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
  display: inline-block !important;
  position: absolute !important;
  min-width: 10px !important;
  padding: 7px 4px !important;
  line-height: 1 !important;
  color: rgb(212, 19, 13) !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  top: -2px !important;
  right: -2px;
  transform: rotateY(0deg) !important;
  width: 22px !important;
  height: 22px !important;
  font-weight: normal !important;
  font-size: 11px !important;
}
.desktopModeNavBar{
  position: fixed;
  background: #d20c14 !important;
  height: 100%;
}
.desktopModeFloatingIcons{
  margin-left: 5.7%;
  position: fixed;
  text-align: center;
  width: auto;
  background-color: transparent;
  /* background-color: rgba(255, 255, 255, .8); */
  z-index: 99;
  height: 100vh;
}

.floatingIconWidth{
  width: 40px !important;
  height: 30px !important;
  background: #d20c14 !important;
    color: #fff !important;
}

.desktopModeFloatingIcons .floatingIconWidth {
  width: 35px !important;
  margin: 10px 10px 0;
}

.desktopModeFloatingIcons button {
  justify-content: flex-start !important;
  outline: 0 !important;
  height: 15px;
  padding: 0;
}

.desktopModeFloatingIcons button .MuiFab-label {
  justify-content: center !important;
  outline: 0 !important;
} 

@media screen and (min-width: 1300px) {
  .footericons {
    width: 19px;
    height: 19px;
  }
.desktopModeNavBar .tab-title {
    font-weight: bold;
    font-size: 12px;
    margin-top: 3px;
  }
  .nav-item img[alt="location"] {
    width: 17px !important;
  }
  .desktopModeNavBar ul li {
    padding: 5px 8px;
  }

}

img[alt="SocialMedia"] {
  width: 20px;
  height: 20px;
}

img[alt="Ideabox"] {
  width: 30px;
  height: 28px;
}

img[alt="polls"] {
  height: 15px !important;
  margin-left: 3px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .menu-scroll {
    /* height: 75vh !important; */
  }
}

.menu-scroll::-webkit-scrollbar {
  width: 5px;  
  background: rgb(210, 12, 20, 0.1);
}

.menu-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1); 
    border-radius: 10px;
    background: rgb(210, 12, 20, 0.4);
}

.menu-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1); 
  background: rgb(210, 12, 20, 0.4);
}


.menu-scroll::-webkit-scrollbar-thumb:hover {
  background: #d20c14;
  padding-top: 20px;
}