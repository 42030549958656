 /* label color */
 .input-field label {
  color: #000;
}
/* label focus color */
.input-field input[type=text]:focus + label {
  color: #000;
}
/* label underline focus color */
.input-field input[type=text]:focus {
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 0 0 #000;
}
/* valid color */
.input-field input[type=text].valid {
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 0 0 #000;
}
/* invalid color */
.input-field input[type=text].invalid {
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 0 0 #000;
}
/* icon prefix focus color */
.input-field .prefix.active {
  color: #000;
}

.logo {
  width: 60%;
}

.loginForm input {
  border: 1px solid #ddd !important;
    padding: 12px 12px !important;
    height: unset !important;
    border-radius: 0px !important;
}

.passsection {
  position: relative;
}

.passsection .password {
  position: absolute;
  width: 25px;
  height: 25px;
  background: url('./images/eye_open.png');
  top: 12px;
  right: 10px;
  background-repeat: no-repeat;
  background-position: 0px 5px;
  cursor: pointer;
  background-size: 90% 95%;
}

.passsection .text {
  position: absolute;
  width: 25px;
  height: 25px;
  background: url('./images/eye_close.png');
  top: 12px;
  right: 10px;
  background-repeat: no-repeat;
  background-position: 0px 5px;
  cursor: pointer;
  background-size: 90% 95%;
}

.loginForm a {
  color: #797979;
  font-size: 15px;
}

.terms {
  color: #d20c14 !important;
  font-size: 13px;
}

.checkboxWrap{
  font-size: 15px;
}

/* .button-bottom,
.bottom-buttons{
    position: fixed !important;
    bottom: 30px;
    width: 87% !important;
    left: 0;
    margin: auto;
    right: 0;
} */

.phonenumner{
  max-width: 69.5% !important;
}

.optionpage {
  width: 60%;
}

.pagelogo {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.mybtn {
  font-family: brandon-grotesque,sans-serif !important;
    /* text-transform: capitalize !important; */
    font-size: 20px !important;
    padding: 10px 10px !important;
    margin: 6px 0;
    border-radius: 0px !important;
    border: 1px solid transparent !important;
    /* font-weight: bold !important; */
}

#loading-icon {
  position: absolute;
  float: left;
  top:50%;
  left:0;
  right: 0;
  text-align: center;
  margin: auto;
}

.selectpage {
  bottom: 60px;
}

.logosection {
  height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

/* .checkbox-bottom{
  position: fixed;
  bottom: 106px;
} */

body.login {
  height: 97vh;
  overflow-y: auto;
  overflow-x: hidden;
}

body.login .content {
  margin-bottom: 0px;
  padding-bottom: 0 !important;
  /* height: 100vh;
  overflow-y: hidden; */
}

.mobileform {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 13vh 44vh 11vh 11vh;
    /* height: 90vh; */
}

.forgotform {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 14vh 48vh 1vh;
  height: 80vh;
}

.countyNumber{
  padding: 13px 13px 13px 13px !important;
  width: 100% !important;
}

.otp-field {
  width: 38px;
  margin-right: 10px;
  /* padding-left: 12px; */
  height: 40px;
  text-align: center;
  font-size: 16px !important;

}

.otp-field {
  width: 40px;
height: 40px;
margin-right: 10px;
text-align: center;
border: 1px solid #ddd;
}

.otp-field:last-child {
 margin-right: 0px !important;
}


.otpform {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 13vh 43vh 0vh;
}


@media only screen and (min-width: 1025px) {
 
 body.login {
   overflow: hidden !important;
 }
} 

.emailLabel{
  text-transform: capitalize;
}

.country-name{
  color: #212529 !important;
}

.Login-Banner{
  width: 100%;
}

@media screen and (min-width: 1300px) {
  body.login .layoutSpace {
    margin: 0 !important;
    padding: 0 !important;
  }
  .login-bg {
    background-image: url('./images/modelLR.jpg');
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  .mybtn {
    font-size: 18px !important;
    padding: 7px 10px !important;
  }
  .forgotform {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .forgotform {
    grid-template-rows: 14vh 43vh 1vh !important;
  }
}

div[data-dismiss="toast" ] {
  text-align: right;
}

.Tabletcontainer .layoutSpace {
  margin-left: 0 !important;
}

.Tabletcontainer .tablet-full-width {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.login .Tabletcontainer .tablet-full-width .logo {
  width: 44% !important;
}

.floating-menu-custom.Tablet-footer {
  right: 130px !important;
  /* bottom: -22%; */
}

.Tabletcontainer .sc-gsTCUz.ePviCp,
.Tabletcontainer .sc-kEjbxe.dmhDzb,
.Tabletcontainer .edMCxs,
.Tabletcontainer .khwMRd  {
  width: 100% !important;
}

.Tabletcontainer  .sc-iBPRYJ.edMCxs { 
  height: 400px !important;
}

.Tabletcontainer .tab-ratealign {
  justify-content: flex-end !important;
}

.Tabletcontainer .contentWrap {
  height: 170px !important;
  width: 180px !important;
  margin: 0 auto 30px !important;
}

.Tabletcontainer .contentWrap .libicons {
  width: 70px !important;
  left: 54px !important;
  top: 27px !important;
}

.Tabletcontainer .space-layout-single {
  width: 88% !important;
  margin:  auto  !important
}

.Tabletcontainer .space-layout-single .customscroll {
  height: 86vh !important;
}

.Tabletcontainer,  .Tabletcontainer .navbar{
  width: 580px !important;
  margin: auto !important;
  float: none !important;
}
/* ipad pro */
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .floating-menu-custom.Tablet-footer {
    right: 238px !important;
    /* bottom: -39%; */
  }
  .Tabletcontainer .TabletLayout{
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
 } 
 @media only screen and (min-width: 1200px) and (max-width: 1366px) and (orientation: landscape){
  .floating-menu-custom.Tablet-footer {
    right: 30% !important;
  }
  .Tabletcontainer .TabletLayout{
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .Tabletcontainer .customscroll.menu-scroll{
    /* height: 740px !important; */
  }
 } 
/* ipad 6 */
 @media only screen and (min-width: 767px) and (max-width: 800px) {
  .floating-menu-custom.Tablet-footer {
    right: 110px !important;
    /* bottom: -18% !important; */
  }  
 }

/* ipad 7 */
 @media only screen and (min-width: 815px) and (max-width: 821px) {
  .floating-menu-custom.Tablet-footer {
    right: 110px !important;
    /* bottom: -29% !important; */
  }  
 }

/* ipad 11 */
 @media only screen and (min-width: 825px) and (max-width: 835px) {
  .floating-menu-custom.Tablet-footer {
    right: 140px !important;
    /* bottom: -29% !important; */
  }  
 }

 .loginSelect:hover {
  text-decoration: underline;
 }