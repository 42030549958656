.reportDetail{
    font-size: 22px;
    margin: 17px 0 !important;
}

.progress {
    width: 104% !important;
    height: 0.5rem;
    border-radius: 0px !important;
}

.surveyLabel {
    font-size: 20px;
}

.surveyPercentage {
    font-size: 15px;
}


.surveygrey {
    background-position: 3px 9px !important;
}

.votePopup .closeButton{
    background-color: #545960;
    color: #fff !important;
    
}

.votePopup button {
    padding: 10px;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    width: 47%;
    margin-right: 10px !important;
}

.votePopup button:last-child {
    margin-right: 0 !important;
}

.votePopup{
    clear: both;
}

.desktopdiv .end-date-2 {
    border: 1px solid #ddd;
}

@media screen and (min-width: 1280px){
    .desktop-width {
        width: 68%;
        margin: auto;
    }
    .surveyLabel {
        font-size: 15px !important;
    }
}