  .browserback {
    width: 35px;
      height: 35px;
      background-color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      text-align: center;
      color: transparent;
      background-image: url('../../Static/images/Back_Button.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15px;
      cursor: pointer;
  }
  

/* .location-thum img {
  width: 115px;
  height: 70px;
} */

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 100%;
  height: 60vh;
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

@media screen and (min-width: 799px) {
  .google-map {
    height: 80vh;
  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }
}
.minimap {
  height: 190px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 6px;
}

.card {
  background: transparent;
    border: 0px solid;
}

.addressdetail {
  line-height: 22px;
  word-break: break-all;
}

.locationdes,
.buttonwrap {
  width: 100%;
  float: left;
}
.buttonwrap button {
    border: 1px solid transparent;
    border-radius: 0px;
    font-weight: bold;
    font-size: 18px;
    width: 48%;
    margin-right: 12px;
    outline: 0;
    padding: 10px;
}

.btn2 {
  margin-right: 0px !important;
}

.buttonwrap button img {
  width: 20px;
  margin-right: 2px;
}

.disabledbutton {
  pointer-events: none;
  background: #545960;
  
}

.clearboth {
  width: 100%;
  float: left;
  clear: both;
}

.buttonwrap {
  border-bottom: 1px solid #ddd;
  padding: 10px 15px;
}
/* .button-border{
  border-bottom: 1px solid #ddd;
}
.noborder{
  border-bottom: 0px !important
}
.custom-margin{
  margin: 0px 9px;
} */

.locationAction p.textcolor1.mb-0,
.locationAction p.disableLink.mb-0 {
  white-space: nowrap;
  margin-top: 3px;
  line-height: normal;
}

.locationlist {
  width: 20px;
  height: 20px;
  background-size: 13px !important;
  background-position: left !important;
}

.locationimg .post-img {
  height: 61px !important;
  width: 94px !important;
}

.locationAction {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.loctionWrap .card-title {
  min-height: 35px;
  margin-bottom: 0 !important;
  height: auto;
}

.minimap {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.iconAlign{
  background-position: 7px 1px;
}

#frame{
  width: 100%;
  float: left;
}
.googlemapping {
  width: 100%;
  height: 205px;
  border: 1px solid transparent;
}
.actionButton-loc {
  width: 30% !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .mob-space-location {
    padding: 0 !important;
  }
}

.desktopdiv .desktop-location {
  display: flex;
  width: 108%;
}

.desktopdiv .desktop-location .desktop-location {
  display: flex;
}

.desktopdiv .desktop-location .addressdetail {
  width: 100%;
}

.location-address li:before {
    content: '';
    width: 4px;
    height: 4px;
    border: 1px solid transparent;
    background-color: #d20c14;
    position: absolute;
    top: 10px;
}

.location-address  li{
  list-style: none;
  position: relative;
  font-size: 15px;
  color: #000;
}

span.left-side-data {
  display: inline-block;
  margin-left: 20px;
  margin-right: 30px;
  width: 125px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .location-address {
    margin-bottom: 10px !important;
  }
  .locationdes {
    /* border-top: 1px solid #ddd; */
    padding-top: 12px
  }
}

@media screen and (min-width: 320px)  and (max-width: 568px) {
  .locationbutton button {
    width: 47%;
  }
}

.location-address .width-adjust-class {
  display: flex;
  width: 100%;
}

span.left-side-data {
  margin-left: 20px;
  margin-right: 30px;
  width: 40%;
  display: flex;
}

span.right-side-date {
  width: 60%;
  display: flex;
  word-break:  break-all;
}

span.right-side-data {
  width: 60%;
  /* height: 30px;
  overflow: auto; */
}

.top_wrap {
  top: 0px !important;
}