.success-checkmark:after {
    content: '✔';
    position: absolute;
    left:0; top: 2px;
    width: 30px;
    height: 30px;
    text-align: center;
    border: 1px solid #aaa;
    background: #E31010;
    padding:2px;
    border-radius: 50%;
    color:#ffffff;
  }

  .current-1:after {
    content: '1';
    position: absolute;
    left:0; top: 2px;
    width: 30px;
    height: 30px;
    text-align: center;
    border: 1px solid #aaa;
    background: #E31010;
    padding:2px;
    border-radius: 50%;
    color:#ffffff;
  }

  .normal-2:after {
    content: '2';
    position: absolute;
    color:#000000 !important;
    left:0; top: 2px;
    width: 30px;
    height: 30px;
    text-align: center;
    border: 1px solid #aaa;
    background: #ffffff;
    padding:2px;
    border-radius: 50%;
    color:#ffffff;
  }


  .current-2:after {
    content: '2';
    position: absolute;
    color:#000000 !important;
    left:0; top: 2px;
    width: 30px;
    height: 30px;
    text-align: center;
    border: 1px solid #aaa;
    background: #ffffff;
    padding:2px;
    border-radius: 50%;
    color:#ffffff;
  }

  .browserback {
    width: 35px;
      height: 35px;
      background-color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      text-align: center;
      color: transparent;
      background-image: url('../../Static/images/Back_Button.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15px;
      cursor: pointer;
  }

  .contact-buttons{
    padding-bottom: 23% !important;
    width: 100%;
    float: left;
    clear: both;
  }

  .date-pick-main-div .react-datepicker-wrapper{
    width: 100% !important;
    float: left;
    margin-bottom: 20px;
  }

  .saveButton{
    height: 50px !important;
    border-radius: 0px !important;
    font-size: 18px !important;
  }
  .react-datepicker-wrapper{
    width: inherit;
  }