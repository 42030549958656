.comment-body img {
  max-width: 100%;
  width: 55px;
  height: 55px;
}
/* textarea.mentions__input{
  position: relative !important;
} */
.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  overflow-y: scroll;
  height: 100px;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  font-weight: bold;
  font-weight: 900;
}
.mentions--multiLine .mentions__input {
  padding: 9px;
  min-height: 63px;
  outline: 0;
  border: 0;
}

.comment {
  background: transparent !important;
  padding: 0 !important;
  border: 0px solid !important;
}

.comment-body {
  padding: 0 !important;
}

.commentdetail {
  background: #fff;
  border: 1px solid #ddd;
  clear: both;
  width: 100%;
  float: left;
}
.commenttext {
  clear: both;
  padding: 0; 
}

.Cname{
  display: inline-block;
}

.commentBox  textarea,
.mentions__highlighter{
  background-color: #fff !important;
  min-height: 54px !important;
  /* padding: 12px 40px 12px 12px !important; */
  padding: 12px 75px 12px 12px !important;
  overflow: auto;
}

.writeComment {
  position: relative;
}

.writeComment button{
  /* position: absolute; */
  right: -11px;
  bottom: 0;
  /* color: transparent; */
  /* background-image: url('../../../Static/images/nextArrow.png'); */
  border: 0px solid !important;
  width: 47px !important;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 25px;
  padding: 0;
  background-position: center;
  top: 0;
  outline: 0;
  background-color: transparent !important;
  color: #d20c14;
}

.commentWrap {
  padding-bottom: 32px;
  clear: both;
}

.mentions__suggestions {
  overflow: hidden;
}


.commentImg {
  width: 22%;
  float: left;
  padding-left: 2% !important;
}

.commentBody {
  width: 74%;
}

.Cname {
word-break: break-word;
}
a.commentTag {
font-weight: bold !important;
color: black !important;
}

@media screen and (min-width: 1280px) {
.commentImg {
  width: 13%;
  text-align: left !important;
}
.commentBody {
  width: 85%;
}

}

@media screen and (min-width: 320px) and (max-width: 767px) {
.mobileSpace {
  padding: 0 !important;
}
}
.example_emoji__button__291KU {
right: 30px !important;
top :2px !important;
padding: 0rem 0rem !important;
background-color: #fff !important;
}
.commentpost {
position:absolute;
cursor: default !important;
}
.emoji__picker {
/* position: absolute;
bottom: 10px;
right: 0;
float: right;
margin-left: 200px; */
position: absolute;
right: 46px;
float: right;
top: 52px;
margin-bottom: 30px;
}
.emoji__button {
position: absolute;
cursor: default !important;
padding: 1rem 0rem;
top: 0;
right: 0;
background-color: white;
border: 0;
right: 30px !important;
}

.emoji-mart-anchors {
padding: 10px 6px !important;
}

.writeComment button {
width: 44px !important;
}

.emoji-mart .emoji-mart-emoji {
padding: 2px 0px !important;
}

@media screen and (min-width: 0px) and (max-width: 1023px) {
.emoji__picker {
  margin-bottom: 100px !important;
  z-index: 99999 !important;
  top: 50px;
  left: 1px !important;
}
}
@media screen and (min-width: 0px) and (max-width: 767px) {
  .emoji_mob {
    padding-left: 10px !important;
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .emoji_sub {
    margin-right: 5px !important;
  }
}