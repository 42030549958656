.signature_label {
  align-items: baseline;
}
.signature_label_font {
  font-size: 16px;
}
.disclaimer_class {
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  background-color: #e9ecef;
  border-radius: 4px;
}
.option_selector .css-yk16xz-control {
  border-radius: 4px !important;
}

@media screen and (min-width: 1280px) {
  .signature_title {
    font-size: 22px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .signature_title {
    font-size: 20px !important;
    margin-top: 5px;
  }
  .option_selector .css-26l3qy-menu {
    width: 90% !important;
  }
  .css-1g6gooi input:focus {
    box-sizing: content-box;
    width: 2px;
    border: 0px;
    font-size: 20px !important;
    opacity: 1;
    outline: 0px;
    padding: 0px;
    color: inherit;
    background-position: 0px center;
    background-repeat: initial initial;
  }
}
