.hide-class{
    display: none;
}

.settingarrow svg {
    color: #d20c14;
    font-size: 18px;
}

.app-install-button{
    border: 1px solid transparent;
    padding: 5px 16px;
    font-weight: bold;
    text-transform: capitalize;
}

.link-button {
    border: 1px solid transparent;
    padding: 5px 16px;
    font-weight: bold;
    text-transform: capitalize;
}
.footertop {
    border-top: 0px solid !important;
}

.footertop button {
    border: 1px solid transparent;
    padding: 7px 30px;
}

.popups-title    {
    padding: 40px 0 15px;
    background-color: #fff;
}

.popups-footer {
    padding: 10px 0 40px;
}

.TCTitle {
    font-size: 23.7px;
    display: block !important;
}

/* .termsandcondition h1{
    display: none;
} */

.scroll-y{
    overflow-y: auto;
    height: 250px;
}
.layoutSpace{
    margin-left: 40px;
  }

  @media screen and (min-width: 1300px) {
    .scroll-y {
        position: relative;
        overflow-y: auto;
        height: 50vh;
      }
      
      .scroll-y::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 100vh;
        background: #ddd;
        right: 0;
        top: 23px;
      }
      
      .scroll-y::-webkit-scrollbar {
        width: 5px;
        
      }
      
      .scroll-y::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
          border-radius: 10px;
      }
      
      .scroll-y::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
      }
      
      
      .scroll-y::-webkit-scrollbar-thumb:hover {
        background: #ddd;
        padding-top: 20px;
      }
      .desktop-button {
          width: 30% !important;
      }
  }