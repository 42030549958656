.initial-reaction-icon {
    width: 20px;
}

.actionButtons {
    width: auto;
    float: right;
    display: inline-block;
}

.reaction-count {
    margin-left: 3px;
    font-size: 10px;
}

.popup-icon {
    width: 50px;
}

.reaction-icon-rigt-space {
    margin-right: 7px;
}

.icon-border{
    border: 1px solid transparent;
    border-radius: 20px;
    padding: 1px 12px 4px;
    box-shadow: 0px 0px 4px #ddd;
    background: #fff;
    position: absolute;
    right: 0;
    top: -32px;
    width: auto;
    z-index: 999999;
}


.icon-border span:last-child .reaction-icon-rigt-space {
    margin-right: 0 !important;
}

.parent-reaction-div {
    position: relative;
    width: 100%;
    float: left;
}

.popup-icon-section {
    width: 20px;
}

.div-width-reaction {
    width: 20px;
    height: 20px;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .initial-reaction-icon {
        height: 18px !important;
        width: 18px;
    }
    .popup-icon-section {
        width: 18px;
    }
  
    
    /* img[alt="/static/media/love.e4b54acd.png"] {
        width: 30px;
        height: 29px !important;
    }
    span[title="/static/media/love.e4b54acd.png"] {
        width: 24px !important;
    } */
}

/* img[alt="/static/media/love.e4b54acd.png"] {
    width: 22px;
}

span[title="/static/media/love.e4b54acd.png"] {
    width: 18px !important;
} */