.Modal-BDay{
    background-image: url('BirthdayGIF.gif');
    background-size: 100%;
    background-repeat: no-repeat;
    height: 242px;
    position: relative;
}

@media only screen and (min-width: 1025px) {
    .Modal-BDay {
        height: 353px;
    }
}

.hide-class{
    display: none;
}

.bdaypopup {
    width: 91%;
    margin: auto;
}

.bdaypopup  img{
    display: none;
}

.Modal-BDay h4 {
    color: #d20c14;
    position: absolute;
    top: -29px;
    width: 100%;
    background: #fff;
    left: 0;
    right: 0;
    padding: 12px 10px;
    margin: 0;
}

.bdaypopup .buttonDiv button {
    padding: 10px;
    border: 0px solid !important;
    border-radius: 0px;
    font-size: 20px;
}
.birthadayButtonClose{
    width: 35px;
}

.birthadayButtonClose {
    position: absolute;
    top: -15px;
    right: -13px;
    width: 33px;
    height: 33px;
    font-weight: bold;
    border-radius: 50%;
    font-size: 21px;
    line-height: 20px;
}

.bdaypopup .modal-content{
    background-color: transparent !important;
}