body {
  font-size: 15px;
}

.bg-color {
    background: #d20c14;
    color: #fff ;
}

.textcolor1,
.textcolor11 {
    color: #d20c14;
}
.events-link a:hover
{
  color: #d20c14;
}
.textcolor2{
   color: #fff;
}

.sectionTitle {
    font-size: 22px;
}

.innerpagetitle {
  font-size: 18px;
  color: #3D3D3D;
}

.secendorytitle {
  font-size: 18px;
}

/* Checkbox design */
.checkboxWrap {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkboxWrap input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #ddd;
  }

  /* On mouse-over, add a grey background color */
  .checkboxWrap:hover input ~ .checkmark {
    border: 1px solid #ddd;
  }

  /* When the checkbox is checked, add a blue background */
  .checkboxWrap input:checked ~ .checkmark {
    background-color: #d20c14;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkboxWrap input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkboxWrap .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .pagetitle {
    font-size: 28px;
  }

  .browserback {
    width: 35px;
      height: 35px;
      background-color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      text-align: center;
      color: transparent;
      background-image: url('../images/Arrow.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 15px;
  }

  .searchsection input {
    background-image: url('../images/search.png');
    height: unset !important;
    padding: 10px 10px 10px 35px !important;
    border: 1px solid #d20c14 !important;
    border-radius: 4px !important;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 9px 14px;

  }
  .card-title {
    color: #3D3D3D;
    font-size: 18px;
    margin-bottom: 6px !important;
    cursor: pointer;
  }


a, a:hover {
  text-decoration: none !important;
}

/* Switch design */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
  margin-bottom: 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* border: 1px solid white; */
  background-color: #d20c14;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-on {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* border: 1px solid white; */
  background-color: #80808096;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.slider-on:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  /* background-color: #2196F3; */
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

input:focus + .slider-on {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider-on:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-on.round {
  border-radius: 34px;
}

.slider-on.round:before {
  border-radius: 50%;
}
.switch-label {
  display: inline-block;
  margin-right: 10px;
}

/* Radio button design */

.radiowrap {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radiowrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 2px solid #d20c14;
  background: transparent;
  border-radius: 50%;
}

.radiowrap:hover input ~ .radiomark {
  border: 2px solid #d20c14;
  background: transparent;
}

.radiowrap input:checked ~ .radiomark {
  border: 2px solid #d20c14;
}

.radiomark:after {
  content: "";
  position: absolute;
  display: none;
}

.radiowrap input:checked ~ .radiomark:after {
  display: block;
}


.radiowrap .radiomark:after {
  content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: auto;
    text-align: center;
    background: #d20c14;
    position: absolute;
    left: 0.6px;
    right: 0;
    top: 2.4px;
}
.tab-anchor {
  cursor: none
}

.image-gallery-slide .image-gallery-image {
  height: 225px;
  object-fit: cover !important;

}

@media only screen and (min-width: 1025px) {
  .image-gallery-slide .image-gallery-image {
    height: 300px;
  }
}

/* Image Sprite */
.imgSprite {
  height: 30px;
  width: 30px;
  background-size: 22px;
  background-repeat: no-repeat;
  /* background-position: top right; */
  /* background-position: 4px 3px; */
  background-position: 3px 3px;
}

.desIcon {
  background-image: url('../images/newsdes.png');
}

.eventIcon {
  background-image: url('../images/event.png');
  background-size: 22px !important;
}

.surveygrey {
  background-image: url('../images/Surveygrey.png');
  background-size: 25px !important;
}

.locationIcon {
  background-image: url('../images/location.png');
  background-size: 17px !important;
  display: inline-block;
    background-position: center;
    float: left;
}

.humburgerIcon {
  background-image: url('../images/humburger.png');
  background-size: 28px !important;
  display: inline-block;
    background-position: center;
}

.closeIcon {
  background-image: url('../images/close.png');
  background-size: 28px !important;
  display: inline-block;
  background-position: center;
}

.locationperson {
  background-image: url('../images/locationpro.png');
  background-size: 17px !important;
  display: inline-block;
  background-position: center;
  float: left;
  margin-right: 8px;
}

.locationRed {
  background-image: url('../images/redlocation.png');
  background-size: 17px !important;
  display: inline-block;
  background-position: center;
  float: left;
  margin-right: 8px;
}

.foodType {
  background-image: url('../images/Food_type.png');
  background-size: 12px !important;
  display: inline-block;
  background-position: left;
  float: left;
  width: 25px !important;
    height: 25px !important;
}

.starRating {
  background-image: url('../images/Star.png');
  background-size: 17px !important;
  display: inline-block;
  background-position: center;
  float: left;
  margin-right: 8px;
}

.btn, button{
  box-shadow: none !important;
  outline: 0;
}

.startdate {
  border: 1px solid #ddd;
    border-left: 0px solid !important;
    font-size: 15px;
}

.enddate{
  border: 1px solid #ddd;
    border-right: 0px solid !important;
    font-size: 15px;
}

.result-btn {
  border-radius: 0px !important;
  padding: 6px 20px !important;
}

.clear {
  clear: both;
}

.tipicon {
  background-size: 12px !important;
  display: inline-block;
  background-position: left;
  float: left;
  width: 25px !important;
  height: 25px !important;

}

.rateAlign {
    height: 20%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    margin-top: 4px;
}

.star {
  background-image: url('../images/Star.png');
  width: 24px;
  height: 30px;
  float: left;
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 0px 9px;
  margin-right: 0px;

}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3) {
 .content {
   padding-bottom: 20% !important;
 }
}

.pdfButtons {
  border: 1px solid transparent !important;
    border-radius: 0px !important;
    padding: 7px 20px !important;
    font-weight: bold !important;
}


.pdfNext {
  padding: 7px 30px !important;
}

/* Browser title and back button */

.topsection-wrap {
  position: relative;
}

.topsection-wrap .browserback {
  position: absolute;
  top: 24px;
}

.disableLink {
  color: #545960;
  pointer-events: none;
}

.datewrap .small {
  font-size: 65% !important;
}

.actionButtons {
  font-size: 15px !important;
  cursor: pointer;
}

.icon-undisplay {
  visibility: hidden;
}

.Richtexterditor table {
  border-collapse: collapse;
  border: 1px #2b2b2b solid;
  width: 100%;
}
.Richtextreditor table, th, td {
  border: 1px solid black;
}

.fontSize {
  font-size: 80%;
}

.Surveyswrap .card-title {
  min-height: 50px !important;
}
.question-popup .radiomark{
  height: 22px !important;
  width: 22px !important;
  margin-top: 6px !important;
}

.question-popup .radiowrap .radiomark:after {
  width: 12px !important;
  height: 12px !important;
  top: 3px !important;
}

.question-popup .radiowrap{
  padding-left: 30px;
}

/* Desktop layout class */

@media only screen and (min-width: 1025px) {

  .customContainer {
    /* width: 50% !important; */
    margin: auto;
  }
  .content {
    padding-bottom: 8% !important;
  }
  .floating-menu-custom {
    right: 27% !important;
  }
}

.customCursor {
  cursor: pointer;
}

.Desktop-mode-list-and-detail-view{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
/* .Desktop-mode-list-view{
  width: 40%;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}
.Desktop-mode-Detail-view{
  width: 60%;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
} */

.Desktop-mode-Detail-view .customscroll {
  margin-right: 3px;
}

.width-adjust-class {
  width: fit-content;
}

.customscroll {
  position: relative;
  overflow-y: auto;
  height: 100vh;
}

.Desktop-mode-list-view::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 100vh;
  background: #ddd;
  right: 0;
  top: 23px;
}

.customscroll::-webkit-scrollbar {
  width: 5px;

}

.customscroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

.customscroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}


.customscroll::-webkit-scrollbar-thumb:hover {
  background: #ddd;
  padding-top: 20px;
}

@media screen and (min-width: 1280px)  {
  .pagetitle {
    font-size: 22px !important;
  }
  .desktopiconalign  p,
   .detailIcon,
   .eventdetailicon,
   .surveydetailicon{
    margin-left: auto;
    }
.tipsRatings .rateAlign {
  margin: 0;
}
   .location-desk .customContainer {
      padding: 0 !important;
   }
   .location-desk .card-group {
     flex-flow: column !important;
   }
   .rateAlign {
    justify-content: flex-end;
   }
   div#row,
   div#pdfWrapper {
     width: 100% !important;
   }
   h5, .Desktop-mode-Detail-view p,
   h4 {
    font-size: 15px !important;
   }
   .buttonwrap button {
     font-size: 15px !important;
   }
   .buttonwrap button img {
     width: 18px !important;
   }
   .locationAction .locationIcon {
     background-size: 15px !important;
   }
}

.Richtexterditor {
  word-break: break-word;
}

@media screen and (min-width: 1300px) and (max-width: 1368px) {
  .space-layout-single {
    width: 85% !important;
    margin: 0% 0% 0% 10.2% !important;
  }
}

@media screen and (min-width: 1490px) and (max-width: 1920px) {
  .space-layout-single {
    width: 85% !important;
    margin: 0% 0% 0% 9.5% !important;
  }
}

@media screen and (min-width: 0px)  and (max-width: 767px) {
  .space-layout-single {
    width: 91%;
    margin: auto;
  }
  .meetingsWrap .card-title {
    text-overflow: ellipsis;
      /* display:inline-block; */
    white-space: nowrap;
    overflow: hidden;
    max-width: 10ch;
  }
}

@media screen and (min-width: 300px)  and (max-width: 600px) {
  .meetingimgs .post-img {
    display: flex;
    height: 74px;
    width: 110px;
    cursor: pointer;
    max-width: 100%;
  }
}

.padding-se {
  padding-right: 1rem!important;
  padding-left: 0.5rem!important;
}

@media screen and (max-width: 325px){
  .padding-se {
    padding-right: .25rem!important;
    padding-left: 1.5rem!important;
  }
}



.searchsectionwithSubmit{
  background-image: url('../images/search.png');
  height: unset !important;
  border-radius: 4px !important;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: 9px 14px;
  border: 1px solid #d20c14 !important;

}

.searchsectionwithSubmit input{
  padding: 10px 10px 10px 35px !important;
  background-color: transparent;
  border: none;
  outline: none;
  height: 100%;
}

.searchsectionwithSubmit input:focus{
  background-color: transparent;
}

.searchsectionwithSubmit input:hover{
  background-color: transparent;
}

.searchsectionwithSubmit .search-submit-arrow{
  color: #d20c14;
  width: 30px;
  height: 100% !important;
  padding:2px 5px 8px 5px !important;
  background-color: transparent;
  border: none;
  height: unset !important;
  position: absolute;
  right: 0px;
  font-size: 23px;
}

.searchsectionwithSubmit .search-submit-arrow:hover{
  color: #d20c14;
}

.meetingAction{
  position:relative;
}

.meeting-agenda {
  bottom: 0px !important;
  position: absolute;
  /* float: right !important; */
  /* right: 10px; */
  text-align: center !important;
}

@media screen and (min-width: 0px)  and (max-width: 767px) {
  .post-img img{
    width: 100% !important;
  }
  .post-img img[src=""] {
    display: none;
}
}
.template-title
{
  font-size: 1.5rem !important;
}
/* .news-mind .image-gallery-slide .image-gallery-image
{
  height:275px;
}
@media screen and (min-width:1400px) and (max-width:1599px){
  .news-mind .image-gallery-slide .image-gallery-image
{
  height:225px;
}
}
@media screen and (min-width:1600px) and (max-width:1799px){
  .news-mind .image-gallery-slide .image-gallery-image
{
  height:245px;
}
}

@media screen and (min-width:1200px) and (max-width:1399px){
  .news-mind .image-gallery-slide .image-gallery-image
{
  height:175px;
}
} */