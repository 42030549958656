.teaser_image {
    width: 100%;
}
.sectional-line {
    border-bottom :1px solid lightgray;
}
.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}

.likesection {
    font-size: 13px;
}

#frame iframe {
    height: 250px;
}

figure img,
figure iframe {
    width: 100% !important;
}

img {
    max-width: 100%;
}
.current-post {
    line-height:100px;
    border-bottom: 1px solid #d20c14 !important;
}

.post-img {
    display: flex;
    height: 74px;
    width: 110px;
    cursor: pointer;
}

.Edate,
.datewrap {
    line-height: normal !important;
}

.Edate {
    font-size: 63%;
}

.eventLink a {
    font-size: 15px;
}

.pollsDate {
    font-size: 73% !important;
}


/* .pollsLink {
    font-size: 18px;
} */


/* .pollswrap {
display: inline-block;
    width: 76%;
    margin-top: 4px;
} */

.eventsWrap .card-title,
.pollsWrap .card-title {
    min-height: 50px;
}


.eventlocation{
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    background-size: 15px !important;
    background-position: 3px 4px !important;
    width: 17px !important;
    margin-right: 11px !important;
}

.eventdetailicon{
    background-position: right 3px;
}

.noevents {
    color: #989DB3;
}

.desktopdiv .minimap {
    height: 186px !important;
}

.desktopdiv .eventlocation {
    height: 25px !important;
}

.go-align-event {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 15px;
    flex-direction: column;
    height: 100%;
    align-content: flex-end;
}

.add-to-agenda {
    padding: 5px 0 0  !important ;
}
.add-to-agenda a, .go-btn{
    font-size: 13px;
}
.go-btn {
    padding: 2px 12px;
}