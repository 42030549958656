.recent-detail {
    border: 1px solid #F9F9F9;
    border-width: 0px 0px 1px 0px !important;
    background: transparent;
    border-bottom: 1px solid #ddd !important;
    border-radius: 0px;
    box-shadow: none;
}

.recent-detail a:hover{
     color: #d20c14;
}

.likesection {
    font-size: 13px;
}

#frames iframe {
    height: 250px;
}

figure img,
figure iframe {
    width: 100% !important;
}

img {
    max-width: 100%;
}

.recent-detail .card-title {
    min-height: 30px;
    margin-bottom: 0 !important;
    height: auto;
    padding-top: 3px;
}

.detailIcon {
    background-position: center right !important;
}

.detailInnertitle {
    display: flex;
    align-items: center;
}

.newsWrap .card-title {
    min-height: 50px;
    padding-top: 3px;
}

@media screen and (min-width: 1025px) {
    .desktop-space {
        padding-right: 0 !important;
    }
    .desktop-space  .icon-border {
        padding: 1.5px 12px 2px 12px !important;
        box-shadow: 0px 0px 4px #ddd;
        background: #fffffff5;
        top: -32px !important;
        z-index: 999999;
    }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .mob-right-space-div {
        padding-right: 0 !important;
    }
}

