.profile-image-main-div {
    text-align: center;
}
.iconstyle{
    height: 35px;
    width: 35px;
    border-radius: 100px;
    overflow: hidden;
    background: #d20c14;
    position: relative;
    bottom: 20px;
}
.iconmainstyle{
    text-align: -webkit-center;
    margin-left: 50px;
}
.ant-modal-body{
    overflow: hidden;
    margin-bottom: 50px;
}

.iconmainstyle svg {
    text-align: center;
    margin: 6px 0px 0px 3px;
    font-size: 21px;
}

.profilepic .ant-modal-title,
.removepopup {
    color: #d20c14;
    font-size: 22px;
}

.profilepic .ant-modal-close-x {
    color: #d20c14 !important;
    font-weight: bold;
    margin-top: -3px;
    margin-left: auto !important;
}

.action-btn {
    font-size: 20px !important;
    height: unset !important;
    padding: 7px 25px 10px 25px !important;
    margin: 14px 0;
    border: 0px solid !important;
    line-height: normal !important;
}

.profilepic .btn {
    font-family: brandon-grotesque !important;
    text-transform: capitalize;
    padding: 10px 22px;
}

.chose-img {
    background: #d20c14 !important;
    color: #fff !important;
}